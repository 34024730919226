export default {
  heading: 'Tangerine, cursive',
  subheading: 'Noto Sans Display',
  body: 'Playfair Display, serif',
  body2: 'Merriweather, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Italianno',
  googleFonts: [
    'Playfair Display:400,500,600,700',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Josefin Sans:100,200,300,400,500,600,700,800,900',
    'Italianno',
    'Tangerine'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
