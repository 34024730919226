export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? Container
    '.gonation': { display: 'none!important' },
    '.container': {
      padding: ['0.75rem 1rem', '', '0.75rem 2.5rem'],
      position: 'absolute',
      backgroundColor: 'white',
      top: '0rem',

      '.smallNavMenu': {
        '.react-reveal': {
          opacity: '1'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.75rem 1rem',
      backgroundColor: 'white',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      '.hamburger': {
        height: '45px',
        width: '45px',
        backgroundColor: 'dark',
        '>div': {
          height: '2px'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      },
      '.smallNavMenu': {
        '.navItem': {
          a: {
            color: 'primary'
          }
        }
      }
    },

    // ? Logo

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem',
      padding: '0rem'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '100px', ''],
        // filter: 'brightness(0) invert(1)',
        padding: '0rem',
        filter: 'unset'
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        padding: '0rem',
        maxHeight: ['40px', '60px', '60px', '60px']
      }
    },

    // ? Small Nav Menu

    '.smallNavMenu': {
      mr: '2rem',
      // '.contact': {
      //   variant: 'buttons.navButton'
      // },
      '.navItem': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        a: {
          variant: 'customVariants.title',
          color: 'primary',
          mb: '0rem',
          fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.75rem', '2rem', '2rem'],
          textTransform: 'capitalize'
        }
      }
    },

    // ? hamburger

    '.hamburger': {
      // borderRadius: '100px',
      padding: '0.75rem',
      margin: '0rem 0rem 0rem 1rem',
      borderColor: 'primary',
      // backgroundColor: 'secondary',
      '> div': {
        // backgroundColor: 'primary',
      }
    },
    '.hamburgerOpen': {
      // borderRadius: '100px',
      margin: ['', '', '', '0rem 0rem 0rem 1rem'],
      padding: '0.75rem',
      borderColor: 'white',
      '> div': {
        backgroundColor: 'white',
        width: '75%'
      }
    },

    // ? Nav Menu

    '.navMenuLogo': {
      display: 'none'
    },

    '.navMenu': {
      transform: 'unset',
      right: '0rem',
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'background'
    },

    '.navMenu, .navMenuOpen': {
      alignItems: ['flex-end'],
      justifyContent: 'flex-start',
      padding: ['6rem 1.25rem 2rem', '6rem 1.25rem 2rem', '6rem 1.25rem 2rem', '6rem 1.25rem 2rem'],
      width: '100%',
      maxWidth: '100%',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: 'background',

      '.navItemDropdown': {
        width: '100%',
        textAlign: 'right',
        '.navItemDropdownMenu': {
          background: 'none',
          borderTop: 'solid 1px grey',
          borderBottom: 'solid 1px grey',
          position: 'static',
          '.navItem': {
            a: {
              textTransform: 'capitalize',
              color: 'dark',
              fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.75rem', '2rem', '2rem']
            }
          }
        }
      },

      '.navItem': {
        textAlign: 'right',
        justifyContent: 'flex-end',
        whiteSpace: 'normal',
        textShadow: 'none',
        a: {
          fontSize: ['2rem', '2rem', '2rem', '2rem', '2.25rem', '2.5rem'],
          color: 'dark',
          textTransform: 'capitalize',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },
      '.phoneSocialContainer': {
        mt: '2rem',
        order: '12',
        flexDirection: ['column', '', '', 'column'],
        alignItems: ['center'],
        justifyContent: 'center',
        textAlign: ['center'],
        width: ['100%', '100%', '100%', '100%'],
        '.phoneContainer': {
          width: '100%'
        },
        a: {
          textAlign: ['center'],
          justifyContent: ['center'],
          fontSize: '1rem',
          margin: '0rem 0rem 0.25rem',
          color: 'dark'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['center'],
        color: 'dark',
        fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '2rem', '2rem']
      },

      '.socialIconsContainer': {
        alignItems: ['center'],
        textAlign: ['center'],
        width: ['100%', '100%', '100%', '100%'],
        justifyContent: 'center',
        '.smallNavHeading ': {
          textAlign: 'center'
        },
        a: {
          textAlign: ['center'],
          justifyContent: ['center'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: '#000'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh',
      opacity: '0'
    },
    '.navBlockOpen': {
      backgroundColor: 'transparent'
    },

    // ?  widgets

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      mb: '2rem',
      a: {
        color: 'primary',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    }
  },

  footer: {
    backgroundColor: 'secondary',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 6rem', '', '0.5rem 3rem'],
    color: 'dark',
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        color: 'dark'
      },
      '.contact-us': { order: '20' }
    },
    '.contactDetails-container': {
      color: 'dark',
      '.address': {
        display: 'none'
      },
      color: 'dark'
    },
    '.copyright': {
      width: '50%',
      color: 'dark',
      textAlign: 'center',
      p: { color: 'dark' }
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          color: 'dark',
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          ':hover': {
            backgroundColor: 'dark'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%',
      filter: 'brightness(0) invert(0)'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2.75rem', '3rem', '4rem', '5rem', '6rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'inherit',
    textAlign: 'inherit',
    textTransform: 'capitalize',
    marginBottom: '1.5rem',
    fontFamily: 'heading',
    order: '2',
    color: 'primary'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'inherit',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'lightgrey',
    fontFamily: 'subheading',
    order: '1',
    letterSpacing: '2px'
  },
  text: {
    order: '3',
    textAlign: 'inherit',
    marginBottom: '3.5rem',
    fontFamily: 'inherit',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'inherit',
      fontWeight: '300',
      fontFamily: 'inherit'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: 'solid 3px',
      color: 'dark'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  // },

  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1.5rem', '2rem', '3rem', '5rem 5rem 3rem'],
    maxWidth: '1200px',
    textAlign: 'left',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '5rem'],
      margin: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      m: '0rem',
      zIndex: '1'
      // color: 'unset',
      // padding: ['1rem', '', '2rem'],
      // alignItems: 'center',
      // justifyContent: 'center',
      // width: '100%',
      // maxWidth: 'unset',
      // margin: ' 0rem auto',
      // textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      fontWeight: 'bold',
      fontFamily: 'body',
      width: '100%',
      whiteSpace: 'nowrap',
      width: '100%',
      margin: '0rem',
      color: 'white',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      order: 1,
      mb: '2rem',
      opacity: '0.9'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['2.75rem', '3rem', '3.5rem', '4rem', '4rem'],
      color: 'white',
      fontFamily: 'heading',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      order: 2
    },
    '.text': {
      variant: 'customVariants.text',
      order: 3,
      fontSize: ['1.25rem', '', '', '1.4rem'],
      p: {
        fontSize: ['1.25rem', '', '', '1.5rem'],
        lineHeight: ['1.5', '1.75', '', '1.75'],
        fontWeight: '300'
      }
    },
    '.linksContainer': {
      order: 4
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-initialized': {
      '.slick-slide': {
        height: '100vh',
        '> div': {
          height: '100vh',
          img: {
            p: ['1rem', '', '2rem'],
            height: '100vh'
            // filter: 'brightness(0.5)'
            // opacity: '0.4'
          }
        }
      },

      '.slick-arrow': {
        left: 'unset',
        right: '1rem',
        top: '2rem',
        bottom: 'unset',
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        width: '40px',
        height: '40px'
      },
      '.slick-prev': {
        right: '4rem'
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepageContact: {
    background: 'unset',
    paddingBottom: '1rem',
    '.title': {
      variant: 'customVariants.title',
      order: '1',
      color: 'lightgrey',
      fontSize: ['2rem', '2.5rem', '3rem', '6rem', '7rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
      color: 'primary'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageBoxes1: {
    // backgroundColor: '#efefef',
    '.box': {
      width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
      margin: '1.5rem',
      border: 'solid 1px',
      borderColor: 'dark',
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      flexGrow: '1',
      backgroundColor: 'white',
      color: 'dark',
      //   ':hover': {
      //     border: 'solid 1px #efefef',
      //     '.image': {
      //       filter: 'grayscale(100%)'
      //     },
      //     backgroundColor: 'white'
      //   },
      '.image': {
        height: ['auto', '', '450px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 1s'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
        transition: 'all ease-in-out 1s',
        padding: '0.5rem 1rem 0rem',
        color: 'primary'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '0rem 1rem',
        color: 'text'
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '0rem 1rem',
        color: 'dark',
        p: {
          color: 'dark'
        }
      },
      '.ctaButton': {
        variant: 'buttons.secondary',
        order: '4',
        margin: 'auto auto 1rem 1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    order: 7,

    '.section': {
      p: [0, 0, 0, 4],
      border: '2px solid black',
      maxWidth: '1200px',
      width: '100%'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'dark'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      fontFamily: 'body'
    },
    '.imageContainer': {
      '.image1': {
        height: '60%',
        margin: 'auto',
        position: 'relative',
        right: ['-2rem', '', '-3rem'],
        // backgroundColor: '#f9f9f9',
        border: 'solid 9px',
        borderColor: 'dark',
        padding: '0!important'
      },
      '.image2': {
        border: 'solid 9px',
        borderColor: 'dark',
        marginBottom: '0.5rem',
        padding: '0!important'
      },
      '.image3': {
        border: 'solid 9px',
        borderColor: 'dark',
        padding: '0!important'
      }
    }
  },

  groomingValues: {
    variant: 'customVariants.sideBySide1',
    order: 9,
    // display: 'none',

    '.section': {
      p: [0, 0, 0, 4],
      border: '2px solid black',
      maxWidth: '1200px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'dark'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      fontFamily: 'body'
    },
    '.imageContainer': {
      '.image1': {
        height: '60%',
        margin: 'auto',
        position: 'relative',
        right: ['-2rem', '', '-3rem'],
        // backgroundColor: '#f9f9f9',
        border: 'solid 9px',
        borderColor: 'dark',
        padding: '0!important'
      },
      '.image2': {
        border: 'solid 9px',
        borderColor: 'dark',
        marginBottom: '0.5rem',
        padding: '0!important'
      },
      '.image3': {
        border: 'solid 9px',
        borderColor: 'dark',
        padding: '0!important'
      }
    }
  },

  groomingDisclaimer: {
    variant: 'customVariants.groomingValues'
  },

  homepageServices: {
    variant: 'customVariants.propertyContentBlock1',
    '.menuSectionTitle': {
      color: 'dark'
    }
  },

  services: {
    order: 7
  },

  boarding: {
    variant: 'customVariants.propertyContentBlock1'
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '8rem 1rem'],
    maxWidth: 'unset',
    backgroundColor: 'white',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
      width: '100%',
      justifyContent: 'center'
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem'
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center'
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem']
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem'
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto'
    }
  },

  homepageBoxes2: {
    padding: '0rem',

    '.box': {
      borderRadius: '0px',
      width: ['100%', '', '50%'],
      margin: '0rem',
      height: '75vh',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3rem',
      color: 'white',
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      '::before': {
        content: "''",
        width: '90%',
        position: 'absolute',
        height: '70%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '-1',
        border: 'solid 2px',
        borderColor: 'dark'
      },
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        margin: '0rem'
      },
      '.text': {
        variant: 'customVariants.text',
        color: 'white',
        p: {
          color: 'white'
        }
      },
      a: {
        order: '4'
      },
      '.image': {
        position: 'absolute',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        zIndex: '-2'
        // filter: 'brightness(0.8)',
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        order: '4',
        margin: '0rem auto',
        borderColor: 'white',
        color: 'white',
        ':hover': {
          backgroundColor: 'primary'
        }
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '', '0rem 0rem'],
    borderTop: '1px solid',
    borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'primary',
      fontFamily: 'subheading',
      width: ['', '', '80%'],
      color: 'white'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['transparent'],
      color: 'text'
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'text',
      flexGrow: '1'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'dark'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  homepageSlider: {
    overflow: 'hidden',
    '.slick-slide': {
      height: '400px',
      '> div': {
        height: '100%',
        img: {
          height: '100%',
          objectFit: 'cover'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    order: 7,
    '.albumTitle': {
      padding: '0.5rem'
    },

    '.albumName': {
      textTransform: 'capitalize'
    },
    '.albumImage': {
      // make span unset and column span unset
      gridColumnEnd: 'unset'
    },
    '.albumPhotos': {
      gridAutoRows: '1fr',
      gridTemplateColumns: ['1fr', '', '1fr 1fr 1fr 1fr'],
      gridAutoRows: ['1fr', '', '177px']
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '2',
    backgroundColor: 'white',
    maxWidth: '100%',
    width: '100%',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      width: '100%',
      maxWidth: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    },
    h3: {
      variant: 'customVariants.subtitle',
      color: 'secondary',
      order: 'unset'
    },
    'p.text': {
      color: 'text',
      maxWidth: '650px',
      margin: '2rem 0rem'
    },
    backgroundPosition: 'center center',
    color: 'dark',
    padding: ['3rem 1rem', '', '3rem 1rem'],
    '.inputField, .textField ': {
      border: 'none',
      borderBottom: '2px solid',
      fontWeight: '300',
      borderColor: 'primary',
      borderWidth: '1px',
      borderRadius: '0px',
      padding: '0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'dark'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      backgroundColor: 'transparent',
      color: 'primary',
      border: 'solid 2px',
      borderColor: 'primary',
      borderRadius: '0px',
      textTransform: 'uppercase',
      fontFamily: 'body',
      width: '100%',
      fontSize: ['1rem', '', '1.1rem'],
      padding: '1rem 2rem',
      transition: 'all ease-in-out 0.5s',
      border: 'solid 1px white',
      margin: '0rem',
      fontWeight: 'normal'
    }
  },

  locationMap: {
    order: '3',
    '.title': { display: 'none!important' }
  },

  // ? ===============================
  // ? =======  Our Property   =======
  // ? ===============================

  propertyContentIntro: {
    variant: 'customVariants.sideBySide1',
    padding: ['2rem', '2rem', '2rem', '2rem'],
    maxWidth: '1300px',
    margin: '0rem auto',
    overflow: 'hidden',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '70vw', '70vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        right: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },
  propertyContentBlock1: {
    variant: 'customVariants.propertyContentIntro',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '60vw', '60vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: 'dark',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  propertyContentBlock2: {
    variant: 'customVariants.propertyContentIntro',
    'div.content': {
      alignItems: 'flex-start',
      padding: ['1rem', '1rem', '1rem 0rem 1rem 0rem', '1rem 0rem 1rem 0rem'],
      textAlign: 'left'
    },
    '.imageContainer': {
      width: '100%',
      'div.lazyload-wrapper': {
        width: '100%',
        margin: ['0rem', '0rem', '0rem', '1rem 0rem 1rem 1rem'],
        img: {
          position: 'static',
          transform: 'unset'
        },
        '::after': {
          content: '',
          display: 'none'
        }
      }
    }
  },

  propertyContentBlock3: {
    variant: 'customVariants.propertyContentIntro',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '70vw', '70vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  pageHero: {},

  waiver: {
    backgroundColor: '#ffeff7',
    '.title': {
      mb: '0rem',
      color: 'black'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },
    '.content, .ctaContainer': {
      flex: 1
    },
    '.content': {
      alignItems: ['', '', '', 'flex-end']
    },
    '.ctaContainer': {
      justifyContent: ['', '', '', 'flex-start']
    }
  },

  appointments: {
    backgroundColor: '#ffeff7',
    '.title': {
      mb: '0rem',
      color: 'black'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  }
}
